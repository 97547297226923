<ng-container *visibilityControl="id">
  <div class="customField" *ngIf="show" [ngSwitch]="type">
    <div class="flex row align-baseline gap8">
      <p class="label">{{label | translate}}</p>
      <p class="opcional" *ngIf="!requiredInput && showOptional">{{'opcional' | translate}}</p>
    </div>
    <!-- INICIO DESPLEGABLE -->
    <mat-form-field class="input select" *ngSwitchCase="'select'">
      <mat-select [formControlName]="name" [required]="requiredInput" [placeholder]="infoPlaceholder | translate"
        [panelClass]="'selectionPanel'" (click)="selectOpen = !selectOpen" (closed)="selectOpen = !selectOpen" #select>
        <ng-container *ngFor="let option of options">
          <mat-option [value]="option[valueOption]" class="option" (click)="selectionChange(option)" (onSelectionChange)="selectionChange(option)"
            *ngIf="option[valueOption] !== undefined && option[labelOption]!== undefined">{{option[labelOption]}}
          </mat-option>
          <div class="divider"></div>
        </ng-container>
      </mat-select>
      <!-- icono custom -->
      <mat-select-trigger matSuffix (click)="selectOpen = !selectOpen">
        <mm-icon-button *ngIf="!selectOpen" [srcIcon]="'./assetsArq/img/icons/expand_grey_close.svg'"></mm-icon-button>
        <mm-icon-button *ngIf="selectOpen" [srcIcon]="'./assetsArq/img/icons/expand_grey_open.svg'"></mm-icon-button>
      </mat-select-trigger>
    </mat-form-field>
    <!-- FIN DESPLEGABLE -->
    <!-- INICIO CALENDAR-DATEPICKER -->
    <mat-form-field class="input" *ngSwitchCase="'calendar'">
      <input matInput [formControlName]="name" [matDatepicker]="picker" [disabled]="disabledInput"
        [required]="requiredInput" [matDatepickerFilter]="filterCalendar" [placeholder]="infoPlaceholder | translate"
        (click)="calendarOpen = !calendarOpen; picker.open()"
        (dateChange)="selectionChange($event)">
      <!-- icono estado abierto -->
      <mat-datepicker-toggle matSuffix [for]="picker" (click)="calendarOpen = !calendarOpen">
        <mm-icon-button matDatepickerToggleIcon *ngIf="calendarOpen" [srcIcon]="'./assetsArq/img/icons/close_grey.svg'"
          (notifyClick)="picker.close()"></mm-icon-button>
      </mat-datepicker-toggle>
      <!-- se usa panelClass para aplicar un estilo propio,
      la cabecera es custom y se usa el componente DatepickerHeader -->
      <mat-datepicker #picker [panelClass]="'datePicker'" (closed)="calendarOpen = !calendarOpen"
        >
      </mat-datepicker>
    </mat-form-field>
    <!-- FIN CALENDAR-DATEPICKER -->
    <!-- INICIO INPUT SEARCH -->
    <mat-form-field class="input select" *ngSwitchCase="'search'">
      <input matInput [placeholder]="infoPlaceholder | translate" [formControlName]="name" [required]="requiredInput"
        [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option class="option" *ngFor="let option of options" [value]="option[labelOption]"
          (click)="selectionChange(option)" (onSelectionChange)="selectionChange(option)">
          {{ option[labelOption] }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <!-- FIN INPUT SEARCH -->
    <!-- DEFAULT - input normal -->
    <mat-form-field class="input" *ngSwitchDefault>
      <input matInput [type]="type" [placeholder]="infoPlaceholder | translate" [formControlName]="name"
        [required]="requiredInput" [pattern]="regex">
      <mm-icon-button matSuffix *ngIf="form.controls[name].invalid && form.controls[name]?.touched"
        [srcIcon]="'./assetsArq/img/icons/close_red.svg'" (notifyClick)="clearValue()"></mm-icon-button>
    </mat-form-field>
    <!-- FIN DEFAULT -->
    <!-- ERROR A MOSTRAR -->
    <mat-error class="error"
      *ngIf="form.controls[name]?.hasError('required') && form.controls[name].invalid && form.controls[name]?.touched">
      {{ 'campoRequerido' | translate}}
    </mat-error>
    <mat-error class="error"
      *ngIf="(form.controls[name]?.hasError('pattern') || form.controls[name]?.hasError('blankSpace')) && form.controls[name].invalid && form.controls[name]?.touched">
      {{ 'formatoIncorrecto' | translate}}
    </mat-error>
    <mat-error class="error"
      *ngIf="form.controls[name]?.hasError('segundoApellido')">
      {{ 'segundoApellido' | translate}}
    </mat-error>
  </div>
</ng-container>
