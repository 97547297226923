import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import waitUntil from 'async-wait-until';
import { isNil } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ConfiguracionCampoVentana } from '../../models/taller/TallerConfiguracionEntidadRes';
import { SessionService } from '../session/session.service';
import { Fichero } from '../../enum/Fichero.enum';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private datepipe!: DatePipe;
  private router!: Router;
  private sessionSrv!: SessionService;
  /**
   * Constructor
   * inyeccion servicios
   */
  constructor() {
    this.datepipe = inject(DatePipe);
    this.router = inject(Router);
    this.sessionSrv = inject(SessionService);
  }
  /**
   * Devuelve true o false
   * si los valores son iguales
   */
  public compararValores(val1: any, val2: any): boolean {
    return val1 === val2;
  }
  /**
   * Devuelve true o false
   * si los objetos son iguales
   */
  public compararObjetos(obj1: any, obj2: any): boolean {
    return Object.entries(obj1).toString() === Object.entries(obj2).toString();
  }
  /**
   * Devuelve true si el array tiene elementos
   * @param array aray de cualquier tipo
   * @returns true o false
   */
  public validarLengthArray(array: any[]): boolean {
    return this.existeElemento(array) && array.length > 0;
  }

  /**
   * Valida si el elemento
   * es distinto de null
   * y undefined
   * @param elem elemento de cualquier tipo
   * @returns true si existe
   */
  public existeElemento(elem: any): boolean {
    return !isNil(elem);
  }

  /**
   * Generación traceid para trazas log
   * @returns string
   */
  public generateTraceId(): string {
    return uuidv4();
  }

  /**
   * generacion headers con traceid
   * @param traceId string-identificador
   * @returns HttpHeaders
   */
  public setTraceIdHeader(traceId: string): HttpHeaders {
    return new HttpHeaders({
      traceid: traceId,
    });
  }
  /**
   * Calcula los minutos que han pasadao
   * entre una fecha determinada y la fecha actual
   * @returns numero - minutos
   */
  public getMinLeft(date: Date): number {
    const fechaActual = new Date();
    const minutesLeft = this.getMinDiff(fechaActual, date);
    return minutesLeft;
  }
  /**
   * Calcula los minutos restantes
   * en base a dos fechas
   * @param startDate fecha inicio
   * @param endDate fecha fin
   * @returns minutos
   */
  public getMinDiff(startDate: Date, endDate: Date) {
    const msInMinute = 60 * 1000;
    return Math.round(
      Math.abs(endDate.getTime() - startDate.getTime()) / msInMinute
    );
  }
  /**
   * Método que devuelve la fecha
   * parseada en función del formato
   * @param fecha
   * @returns
   */
  public parseDate(fecha: Date | string, formato: string): string {
    const newDate = new Date(fecha);
    return this.datepipe.transform(newDate, formato)!;
  }
  /**
   * Validaciones sobre la fecha
   * y su formateo si es necesario para mostrarla en ventana
   * @param fecha
   */
  public formatDateToDisplay(fecha: string) {
    let transformed: string = fecha;
    if (this.existeElemento(fecha) && !fecha?.includes('/')) {
      transformed = this.parseDate(fecha!, 'dd/MM/YYYY');
    }
    return transformed;
  }
  /**
   * Valida que no sea la primera pantalla donde carga keycloak
   * y navega a ella
   */
  public redireccionarUrlAnterior() {
    if (!this.compararValores(this.sessionSrv.ultimaUrlVisitada, '/')) {
      this.router.navigate([this.sessionSrv.ultimaUrlVisitada]);
    }
  }
  /**
   * Redirecciona a la ruta recibida por parámetro
   * No es necesario pasar la primera /
   * Ej: 'contratacion/altaPropuesta/resumenPropuesta'
   * 'inicio'
   */
  public redireccionar(ruta: string) {
    this.router.navigate(['/' + this.sessionSrv.idiomaActual + '/' + ruta]);
  }
  /**
   * Método que se llama desde
   * los componentes del menu para
   * marcar la ruta activa por ventana
   * @param path nombre del path
   * @returns true po false
   */
  isRouteActive(path: string) {
    return this.router.url.includes(path);
  }
  /**
   * Método que evita que se lance un evento
   * @param event
   */
  detenerPropagacion(event: any) {
    event.stopPropagation();
  }

  /**
   * Método que pone el foco en un
   * elemento del html, identificado
   * por su id
   */
  async focusResults(idElemento: string) {
    await waitUntil(
      () => this.existeElemento(document.getElementById(idElemento)),
      { timeout: 120 * 1000 }
    );
    document.getElementById(idElemento)!.scrollIntoView({ behavior: 'smooth' });
    this.sessionSrv.showSpinner = false;
  }
  /**
   * Devuelve el primer elemento que
   * cumpla con la condicion
   * @param array lista donde buscar
   * @param campo nombre del campo
   * @returns elemento encontrado
   */
  getFirstCoincidence(
    array: any[],
    campo: string,
    condicion?: (elemento: any, campo: string) => boolean
  ): any {
    let elem = undefined;
    if (this.existeElemento(array) && this.validarLengthArray(array)) {
      elem = array.find((element: any) =>
        this.existeElemento(condicion)
          ? condicion!(element, campo)
          : this.existeElemento(element[campo])
      );
    }
    return elem;
  }
  /**
   * Crea un objeto de configuración para los inputs
   * @param requerido
   * @param visible
   * @param modificable
   * @returns
   */
  getConfigCampo(
    requerido: boolean,
    visible: boolean,
    modificable: boolean = false
  ): ConfiguracionCampoVentana {
    const config = {} as ConfiguracionCampoVentana;
    config.requerido = requerido;
    config.visible = visible;
    config.modificable = modificable;
    return config;
  }

  /**
   * Comprueba si contiene únicamente números y convierte a
   * numérico para que la vista pueda trabajar con ello
   * correctamente
   * @param value tipo string
   * @returns numérico o string
   */
  transformarValorCatalogoValores(value: string) {
    let res: number | string = value;
    const regex = /^\d+$/;

    if (regex.test(value)) {
      res = Number(value);
    }
    return res;
  }

  /**
   * Filtro para que solo se habilite el dia 1
   * de cada mes en el calendario, el resto
   * saldrán bloqueados.
   * Se pasa por Input al componente: mm-input type=calendar
   */
  dateFilterDays = (date: any): boolean => {
    let enable = false;
    if (date) {
      enable = this.compararValores(date.toDate().getDate(), 1);
    }
    return enable;
  };
  /**
   * Devuelve el valor de un campo
   * de un elemento del array.
   * Primero se valida que el array contenga elemento y que el elemento
   * de la posicion indicada exista
   * @param array
   * @param nombreCampo
   * @param indexArray
   * @returns valor del campo de un elemento
   */
  obtenerValorCampoArray(
    array: any[],
    nombreCampo: string,
    indexArray: number
  ) {
    let res;
    if (
      this.validarLengthArray(array) &&
      this.existeElemento(array[indexArray])
    ) {
      res = array[indexArray][nombreCampo];
    }
    return res;
  }
  /**
   * Recibe el número completo de una cuenta bancaria
   * y lo separa por numero de iban y numero de cuenta
   * @param cuentaBancaria
   * @returns
   */
  splitIban(cuentaBancaria: string): { iban: string; numeroCuenta: string } {
    const ibanSinEspacios = cuentaBancaria.replace(/\s+/g, '');
    return {
      iban: ibanSinEspacios?.substring(0, 4),
      numeroCuenta: ibanSinEspacios?.substring(4),
    };
  }
  /**
   * Retorna las posiciones del array donde hay valores duplicadas
   * @param array de tipo string o number
   * @returns array de number con las posiciones duplicadas
   */
  getDuplicados(array: any[]): number[] {
    const duplicados: number[] = [];
    if (this.validarLengthArray(array)) {
      array.forEach((value: any, index: number) => {
        const indexDuplicado = array.findIndex(
          (val: any, indexDup: number) =>
            this.existeElemento(value) &&
            !this.compararValores(value, '') &&
            this.compararValores(value, val) &&
            !this.compararValores(index, indexDup)
        );
        if (!this.compararValores(indexDuplicado, -1)) {
          duplicados.push(index);
        }
      });
    }
    return duplicados;
  }
  /**
   * Método que añade caracteres a izquierda o derecha
   * @param value cadena texto original
   * @param maxLength máximo de caracteres permitidos
   * @param caracter string a insertar n veces en la cadena
   * @param position lugar en el que se quiere añadir el caracter (al inicio o al final de la cadena)
   * @returns
   */
  fillString(
    value: string,
    maxLength: number,
    caracter: string,
    position: 'start' | 'end'
  ) {
    let result: string;
    if (this.compararValores(position, 'start')) {
      result = value.padStart(maxLength, caracter);
    } else {
      result = value.padEnd(maxLength, caracter);
    }
    return result;
  }
  /**
   * Transforma los formatos
   * @param formatos ['PDF', 'JPEG']
   * @returns ['.pdf','.jpeg']
   */
  convertirFormatosToExtensiones(formatos: Fichero[]) {
    let extensiones: string[] = [];
    if (this.validarLengthArray(formatos)) {
      extensiones = formatos.map((formato: Fichero) => {
        return '.' + formato.toLowerCase();
      });
    }
    return extensiones;
  }
  /**
   * Conversión de bytes a megabytes
   * @param bytes
   * @param decimals nº decimales a devolver
   * @returns mbbytes
   */
  bytesToMB(bytes: number, decimals: number = 2): string {
    const mb = bytes / (1024 * 1024);
    return mb.toFixed(decimals); // Redondea a los decimales especificados
  }
  /**
   * Conversión de megabytes a bytes
   * @param mb megabytes
   * @returns bytes
   */
  mbToBytes(mb: number): number {
    return Math.round(mb * 1024 * 1024);
  }
  /**
   * Lógica común para las aplicaciones a aplicar en
   * app.component.ts en método ngOnInit.
   * Se pasa por parámetro la lógica que sea propia de cada app,
   * si no hay lógica custom no es necesario enviar nada
   * @param customLogic código custom a ejecutar
   */
  onInitComunApp(customLogic: () => void | Promise<void> = () => {}) {
    this.sessionSrv.showSpinner = true;
    this.sessionSrv.urlActual = this.router.url;
    this.router.events.subscribe(async (event: any) => {
      if (event instanceof NavigationEnd) {
        this.controlRecargaVentana(event);
        this.sessionSrv.ultimaUrlVisitada = this.sessionSrv.urlActual;
        const hashIndex = event.url.indexOf('#');
        this.sessionSrv.urlActual = !this.compararValores(hashIndex, -1)
          ? event.urlAfterRedirects.split('#')[0]
          : event.urlAfterRedirects;
        /**Se eliminan los parámetros que añade keycloak a la ruta que no son necesarios */
        history.replaceState({}, document.title, window.location.pathname);
        window.scroll(0, 0);
        await customLogic();
      }
    });
  }
  /**
   * Método para realizar una recarga sobre la misma ventana
   * sin redirección ni cambios en la ruta del componente de la ventana actual
   * Necesario incluir en app-routing.module:
   * @NgModule({
   *   imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
   *   exports: [RouterModule]
   * })
   */
  controlRecargaVentana(event: NavigationEnd) {
    if (
      this.compararValores(event.urlAfterRedirects, this.sessionSrv.urlActual)
    ) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([event.urlAfterRedirects]);
      });
    }
  }

  /**
   * Método que revisa el tipo de
   * navegador para usar
   * las imágenes de móvil o
   * desktop
   */
  public isMobile(): boolean {
    const ua = navigator.userAgent;
    const isIpadSafari = /iPad/.test(ua) && !/CriOS/.test(ua) && /Safari/.test(ua);
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua) || isIpadSafari) {
      return true;
    }
    return false;
  }
}
